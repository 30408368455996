<template>
  <b-card
    v-if="evaluation"
    class="mb-1"
  >
    <b-card-title class="mt-1 mb-75">
      <feather-icon
        icon="CompassIcon"
        size="19"
      /> الحدود والأطوال
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="column-1 mb-2">
          <h6 class="section-label">
            شمالاً
          </h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.dimension.north }}
          </b-card-text>
        </div>
        <div class="column-1 mb-2">
          <h6 class="section-label">
            جنوباً
          </h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.dimension.south }}
          </b-card-text>
        </div>
        <div class="column-1 mb-2">
          <h6 class="section-label">
            شرقاً
          </h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.dimension.east }}
          </b-card-text>
        </div>
        <div class="column-1 mb-2">
          <h6 class="section-label">
            غرباً
          </h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.dimension.west }}
          </b-card-text>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <div class="column-2 mb-2">
          <h6 class="section-label">
            بطول
          </h6>
          <b-badge
            v-for="(item, index) in evaluation.request.dimension.north_length"
            :key="`north-${index}`"
            variant="light-info"
            class="mr-1"
          >
            {{ item }} م
          </b-badge>
        </div>
        <div class="column-2 mb-2">
          <h6 class="section-label">
            بطول
          </h6>
          <b-badge
            v-for="(item, index) in evaluation.request.dimension.south_length"
            :key="`south-${index}`"
            variant="light-info"
            class="mr-1"
          >
            {{ item }} م
          </b-badge>
        </div>
        <div class="column-2 mb-2">
          <h6 class="section-label">
            بطول
          </h6>
          <b-badge
            v-for="(item, index) in evaluation.request.dimension.east_length"
            :key="`east-${index}`"
            variant="light-info"
            class="mr-1"
          >
            {{ item }} م
          </b-badge>
        </div>
        <div class="column-2 mb-2">
          <h6 class="section-label">
            بطول
          </h6>
          <b-badge
            v-for="(item, index) in evaluation.request.dimension.west_length"
            :key="`west-${index}`"
            variant="light-info"
            class="mr-1"
          >
            {{ item }} م
          </b-badge>
        </div>
      </b-col>
    </b-row>

    <b-card-title class="mt-1 mb-75">
      <feather-icon
        icon="MapIcon"
        size="19"
      /> المساحات
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row
      v-for="(area, i) in evaluation.request.areas"
      :key="`area-${i}`"
    >
      <b-col
        cols="12"
        md="4"
      >
        <div class="column-1 mb-2">
          <h6 class="section-label">
            نوع المساحة
          </h6>
          <b-card-text
            variant="light-info"
            class="mr-1"
          >
            <template v-if="area.property_area.name === 'أخرى'">
              {{ area.label }}
            </template>
            <template v-else>
              {{ area.property_area.name }}
            </template>
          </b-card-text>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div class="column-3 mb-2">
          <h6 class="section-label">
            المساحة
          </h6>
          <b-card-text
            variant="light-info"
            class="mr-1"
          >
            {{ area.surface }} م
          </b-card-text>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'Dimensions',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    evaluation: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>

<template>
  <div>
    <b-card
      v-if="evaluation"
      no-body
      class="border-primary"
    >
      <b-card-header
        class="d-flex justify-content-between align-items-center pt-75 pb-25"
      >
        <h5 class="mb-0">
          تفاصيل التقييم
        </h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <ul class="list-unstyled my-1">
              <li>
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      rounded
                      variant="primary"
                      size="42"
                      text="م"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      {{ evaluation.evaluated_by.name }}
                    </h6>
                    <small>مقييم</small>
                  </b-media-body>
                </b-media>
              </li>
              <li>
                <span
                  class="align-middle"
                >رقم المعاملة: {{ evaluation.reference }}</span>
              </li>
              <li>
                <span
                  class="align-middle"
                >موضوع التقييم: {{ getEvaluationSubject() }}</span>
              </li>
              <li>
                <span
                  class="align-middle"
                >التقييم لصالح: {{ evaluation.request.client.name }}</span>
              </li>
              <li>
                <span
                  class="align-middle"
                >تاريخ التقييم: {{ formatDate(evaluation.created_at) }}</span>
              </li>
              <li>
                <span class="align-middle">
                  حالة التقييم:
                  <b-badge
                    :variant="
                      evaluation.status == 'ملغى'
                        ? 'light-danger'
                        : 'light-primary'
                    "
                  >
                    {{ evaluation.status }}
                  </b-badge></span>
              </li>
              <li>
                <span class="align-middle">
                  حالة طلب التقييم:
                  <b-badge
                    :variant="
                      evaluation.request.status == 'ملغى'
                        ? 'light-danger'
                        : 'light-primary'
                    "
                  >
                    {{ evaluation.request.status }}
                  </b-badge></span>
              </li>
            </ul>

            <!-------------Download------------->
            <b-dropdown
              v-if="$can('create', 'evaluationRequest')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              right
              text="تصدير"
              size="sm"
              class="btn-sm mr-2"
              variant="primary"
            >
              <b-dropdown-item
                v-for="(report, i) in reports"
                :key="i"
                @click="download(report.id)"
              >
                {{ report.name }}
              </b-dropdown-item>
            </b-dropdown>
            <!-------------Download------------->

            <!-- <b-button
            v-if="$can('review', 'evaluation')"
              class="btn-sm mr-2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="approve()"
            >
            <span>تحويل المعاملة للإعتماد </span>
            <feather-icon icon="CheckCircleIcon" class="mr-25" />
            </b-button> -->
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle"> عدد الملفات المرفقة #: {{ totalAttachmentsCount }}</span>
              </li>
              <li>
                <span class="align-middle">عدد الصور المرفقة #: {{ propertyImagesCount }}</span>
              </li>
              <li>
                <span class="align-middle">
                  تم الرفع على نظام قيمة:
                  <b-badge variant="light-info"> {{ evaluation_on_value_system_feature.value }}</b-badge>
                </span>
              </li>
              <li>
                <span class="align-middle">
                  مبلغ التقييم الكلى:  {{ calculateTotal }}
                </span>
              </li>
              <li>
                <span class="align-middle">
                  تاريخ الاعتماد النهائى:
                  {{ evaluation.accredited_at ? evaluation.accredited_at : 'لم يتم اعتماد التقييم' }}
                </span>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BAlert,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import * as moment from 'moment';
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import evaluationStore from '../evaluationStore';

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BAlert,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    evaluation: {
      type: Object,
      required: true,
    },
    reports: {
      type: Array,
      required: true,
    },
  },
  computed: {
    evaluation_on_value_system_feature() {
      return this.evaluation.features.find(
        (feature) => feature.feature.name === 'evaluation_on_value_system',
      );
    },
    calculateTotal() {
      let total = 0;
      this.evaluation.areas.forEach((area) => {
        total += (area.surface || 0) * (area.value || 0);
      });

      // Check if the total is a valid number
      if (isNaN(total)) {
        return 'لم يتم التقييم';
      }

      return `${total.toFixed(2)} ريال`;
    },
    totalAttachmentsCount() {
      const evaluationAttachmentsCount = this.evaluation.attachments.length;
      const requestAttachmentsCount = this.evaluation.request.attachments.length;

      return evaluationAttachmentsCount + requestAttachmentsCount;
    },
    propertyImagesCount() {
      const allAttachments = [
        ...this.evaluation.attachments,
        ...this.evaluation.request.attachments,
      ];

      const propertyImages = allAttachments.filter(
        (attachment) => attachment.type === 'صور العقار',
      );

      return propertyImages.length;
    },
  },
  methods: {
    moment() {
      return moment();
    },
    formatDate(date, format = 'YYYY-MM-DD hh:mm') {
      return moment(date).format(format);
    },
    canReview() {
      return (
        this.$can('review', 'evaluation')
        && typeof ['تحت التّقييم', 'مفتوح', 'ملغى'].find(
          (status) => status.localeCompare(this.evaluation.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    canSubmitForApproval() {
      return (
        this.$can('approve', 'evaluation')
        && typeof ['تم التّقييم'].find(
          (status) => status.localeCompare(this.evaluation.status, 'ar') == 0,
        ) !== 'undefined'
      );
    },
    approve() {
      // change the status of the evaluation
      this.$toast.info('جاري التحويل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store
        .dispatch('evaluation/approveEvaluation', { id: this.evaluation.id })
        .then((response) => {
          this.$toast.success('تم التحويل بنجاح');
        });
    },
    download(report_id) {
      this.$toast.info('جاري التنزيل...', {
        autoHide: false,
        appendToast: ToastificationContent,
      });
      store
        .dispatch('evaluation/downloadEvaluation', {
          id: this.evaluation.id,
          report_id,
        })
        .then((response) => {
          setTimeout(() => {
            this.downloadFile(response.data, this.evaluation.reference);
          }, 3000);
          this.$toast.success('تم التنزيل بنجاح');
        })
        .catch((error) => {
          console.error('Download error:', error);
          this.$toast.error('حدث خطأ اثناء التنزيل');
        });
    },
    downloadFile(blob, filename) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${filename}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    viewReport(html) {
      const newWin = open(null, 'windowName');
      newWin.document.write(html);
    },

    getEvaluationSubject() {
      return `${this.evaluation.property.classification.name} ${this.evaluation.property.type.name} في ${this.evaluation.property.city.name_ar} ${this.evaluation.property.district.name_ar}`;
    },
    updateEvaluation(data) {
      this.$store
        .dispatch('evaluation/assignEvaluator', {
          evaluationData: data,
          id: this.evaluation.id,
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          console.log('emit refresh');
          this.$emit('refresh');
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    moment() {
      return moment();
    },
    assignEvaluation() {},
  },
  setup() {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

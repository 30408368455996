<template>
  <div v-if="evaluation">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <evaluation-details-card
          :evaluation="evaluation"
          :reports="reports_data"
          @refresh="refresh"
        />
      </b-col>
    </b-row>

    <!-- ================ -->
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-tabs pills>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
              <span class="d-none d-sm-inline">{{ $t("طلب التقييم") }}</span>
            </template>
            <b-card
              v-if="evaluation"
              no-body
              class="px-2 py-2 border-primary"
            >
              <b-card-body>
                <b-tabs pills>
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
                      <span class="d-none d-sm-inline">{{ $t("موقع العقار") }}</span>
                    </template>
                    <b-card
                      no-body
                      class="mb-1"
                    >
                      <div class="card-header">
                        <!-- Title -->
                        <div>
                          <b-card-title>موقع العقار</b-card-title>
                        </div>
                      </div>
                      <b-col
                        cols="12"
                        md="12"
                        lg="12"
                        class="mt-0"
                      >
                        <small>
                          رابط الخريطة:
                          <a
                            :href="`https://www.google.com/maps/search/?api=1&query=${evaluation.property.lat},${evaluation.property.lng}`"
                            target="_blank"
                            style="text-decoration: underline; color: blue; cursor: pointer;"
                          >
                            <feather-icon
                              icon="ExternalLinkIcon"
                              class="ml-1"
                              size="16"
                            />
                          </a>

                        </small>
                      </b-col>
                      <div class="m-2">
                        <l-map
                          ref="leafletMap"
                          :zoom="zoom"
                          :center="center"
                        >
                          <v-tilelayer-googlemutant
                            :key="googleMapKey"
                            ref="googleMutantMap"
                            :apikey="apiKey"
                            type="roadmap"
                            lang="ar"
                            :options="options"
                          />
                          <l-marker :lat-lng="markerLatLng" />
                          <l-control class="">
                            <b-button-group style="font-family: Cairo, Helvetica, Arial, serif;">
                              <b-button
                                :variant="options.type == 'satellite' ? 'primary' : 'light'"
                                @click="updateMapType('satellite')"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="LayersIcon"
                                />
                                قمر صناعي
                              </b-button>
                              <b-button
                                :variant="options.type == 'roadmap' ? 'primary' : 'light'"
                                @click="updateMapType('roadmap')"
                              >
                                <feather-icon
                                  class="mr-25"
                                  icon="LayersIcon"
                                />
                                تضاريس
                              </b-button>
                            </b-button-group>
                          </l-control>
                        </l-map>
                      </div>
                      <div class="card-header">
                        <!-- Title -->
                        <div>
                          <b-card-title>صورة المخطط</b-card-title>
                        </div>
                      </div>
                      <div
                        v-if="evaluation.attachments.filter(attachment => attachment.type === 'صورة المخطط').length > 0"
                        class="mb-2"
                      >
                        <b-img
                          :src="evaluation.attachments.find(attachment => attachment.type === 'صورة المخطط').attachment_url"
                          fluid
                          alt="صورة المخطط"
                        />
                      </div>
                      <div
                        v-else
                        class="mb-2"
                      >
                        لا يوجد صورة مخطط
                      </div>
                    </b-card>
                  </b-tab>
                  <!-- Tab: Information -->
                  <b-tab>
                    <template #title>
                      <span class="d-none d-sm-inline">{{ $t("معلومات العقار") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <property
                        :property="evaluation.property"
                        :evaluation="evaluation"
                      />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span class="d-none d-sm-inline">{{ $t("معلومات الملكية") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <property-info
                        :evaluation="evaluation"
                      />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span class="d-none d-sm-inline">{{ $t("الأطوال والمساحات") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <dimensions
                        :evaluation="evaluation"
                      />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span class="d-none d-sm-inline">{{ $t("المرفقات") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <attachments
                        :attachments="evaluation.request.attachments"
                      />
                    </b-col>
                  </b-tab>
                </b-tabs>
              </b-card-body>
            </b-card>
          </b-tab>

          <!-- Tab: Information -->
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
              <span class="d-none d-sm-inline">{{ $t("التقييم") }}</span>
            </template>
            <b-card
              v-if="evaluation"
              no-body
              class="px-2 py-2 border-primary"
            >
              <b-card-body>
                <b-tabs pills>
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
                      <span class="d-none d-sm-inline">{{ $t("معلومات العقار") }}</span>
                    </template>
                    <b-col
                      v-for="(group) in evaluationGroupsData.filter((group) =>['property_info', 'property_front', 'property_floor'].includes(group.name))"
                      :key="group.id"
                      cols="12"
                      md="12"
                    >
                      <property-group :group="group" />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
                      <span class="d-none d-sm-inline">{{ $t("معلومات عامة") }}</span>
                    </template>
                    <b-col
                      v-for="(group) in evaluationGroupsData.filter((group) =>['property_other_info', 'valuation_method_info', 'authority_requirements'].includes(group.name))"
                      :key="group.id"
                      cols="12"
                      md="12"
                    >
                      <property-group :group="group" />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
                      <span class="d-none d-sm-inline">{{ $t("مواصفات العقار") }}</span>
                    </template>
                    <b-card class="mb-1">
                      <b-row>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-alert
                            variant="info"
                            :show="!evaluationGroupsData.some((group) => group.name === 'property_description')"
                            class="mb-0"
                          >
                            <div class="alert-body">
                              <feather-icon
                                icon="InfoIcon"
                                class="mr-50"
                              />
                              لا يوجد حقول  للعقار من فئة <strong>{{ type.name }}</strong>
                            </div>
                          </b-alert>
                          <b-alert
                            v-model="showMessage"
                            dismissible
                            :variant="messageType"
                          >
                            <div class="alert-body">
                              <feather-icon
                                class="mr-25"
                                icon="InfoIcon"
                              />
                              <span
                                class="ml-25"
                                v-html="message"
                              />
                            </div>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </b-card>
                    <b-col
                      v-for="(group) in evaluationGroupsData.filter((group) =>['property_description'].includes(group.name))"
                      :key="group.id"
                      cols="12"
                      md="12"
                    >
                      <property-group :group=" group " />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <!-- <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" /> -->
                      <span class="d-none d-sm-inline">{{ $t("تقييم العقار") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <property-evaluation-view :evaluation="evaluation" />
                    </b-col>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span class="d-none d-sm-inline">{{ $t("المرفقات") }}</span>
                    </template>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <attachments
                        :attachments=" evaluation.attachments "
                      />
                    </b-col>
                  </b-tab>
                </b-tabs>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LControl,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import {
  BAlert,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BButton,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BCol,
  BTable,
  BProgress,
  BBadge,
  BCard,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BButtonGroup,
  BImg,
} from 'bootstrap-vue';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import { Icon } from 'leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import EvaluationRequestAdd from '../../evaluationRequest/evaluationRequest-add/general-info/EvaluationRequestAdd.vue';

import PropertySpecification from './PropertySpecification.vue';
import PropertyGroup from './PropertyGroup.vue';
import PropertyEvaluationView from './PropertyEvaluationView.vue';
import evaluationStore from '../evaluationStore';
import EvaluationDetailsCard from './EvaluationDetailsCard.vue';
import PropertyInfo from './PropertyInfo.vue';
import Property from './Property.vue';
import Dimensions from './Dimensions.vue';
import Attachments from './Attachments.vue';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  directives: {
    Ripple,
  },
  components: {
    EvaluationDetailsCard,
    Property,
    PropertyInfo,
    PropertySpecification,
    PropertyGroup,
    PropertyEvaluationView,
    EvaluationRequestAdd,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BProgress,
    BBadge,
    BCard,
    BAlert,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    BTab,
    BTabs,
    BButtonGroup,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
    PropertySpecification,
    PropertyInfo,
    Dimensions,
    Attachments,
    BImg,
  },
  mixins: [alertMessageMixin, heightTransition],
  data() {
    return {
      options: {
        type: 'roadmap',
      },
      apiKey: 'AIzaSyBZWiGSnsqX38EMwg-vdOS-7TxUgCpSc1U',
      map: null,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      id: router.currentRoute.params.id,
    };
  },
  mounted() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
    this.map = this.$refs.leafletMap?.mapObject;
  },
  methods: {
    updateMapType(type) {
      this.options = {
        ...this.options,
        type,
      };
      this.googleMapKey += 1;
    },
    refresh() {
      this.fetchEvaluation().then(() => {
        this.$forceUpdate();
      });
    },
    async fetchEvaluation() {
      this.$store
        .dispatch('evaluation/fetchEvaluation', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          const { data, reports } = response.data;
          this.evaluation = data;
          this.reports = reports;
          this.showUpdateCostsForm = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            evaluation.value = undefined;
          }
        });
    },
  },
  setup() {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    const type = ref([]);
    const center = ref([]);
    const markerLatLng = ref([]);
    const markerLatLngC = ref([]);
    const evaluation = ref(null);
    const reports_data = ref([]);
    const evaluationGroupsData = ref(null);
    const googleMapKey = ref(0);
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    store
      .dispatch('evaluation/fetchEvaluation', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data, reports } = response.data;
        evaluation.value = data;
        reports_data.value = reports;
        const { property } = data;
        type.value = property.type;
        center.value = [property.city.lat, property.city.lng];
        markerLatLng.value = [property.city.lat, property.city.lng];
        // markerLatLngC.value = [property.city.lat + 0.001,property.city.lng + 0.001];
        store
          .dispatch('evaluation/fetchFeatures', { id: property.type.id })
          .then((response) => {
            const { data: features } = response.data;
            evaluationGroupsData.value = features.groups.map((group) => ({
              ...group,
              features: group.features
                .filter(
                  (feature) => feature.property_type_id == property.type.id,
                )
                .map((feature) => {
                  // Extracting value from data.features
                  const featureData = data.features.find(
                    (dataFeature) => dataFeature.feature_id == feature.feature.id,
                  );
                    // Return the feature with additional value property
                  return {
                    ...feature,
                    value: featureData ? featureData.value : null,
                  };
                }),
            }));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    return {
      type,
      center,
      markerLatLngC,
      googleMapKey,
      markerLatLng,
      evaluation,
      reports_data,
      evaluationGroupsData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/map-leaflet.scss";

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>

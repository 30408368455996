<template>
  <b-card v-if="evaluation " class="mb-1">
    <b-card-title class="mt-1 mb-75">
      <feather-icon icon="InfoIcon" size="18" /> معلومات الملكية
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row>
      <b-col cols="12" md="6">
        <div class="column-1">
          <!-- <h6 class="section-label">المرجعي</h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.reference }}
          </b-card-text> -->
          <h6 class="section-label">
            اسم الجهة
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('client_info').value.client_name}}
          </b-card-text>
          <h6 class="section-label">
            اسم العميل
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('client_info').value.client_name}}
          </b-card-text>
          <h6 class="section-label">
            رقم الصك
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('instrument_number').value}}
          </b-card-text>
          <h6 class="section-label">
            تاريخ الصك
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('instrument_date').value}}
          </b-card-text>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <div class="column-2">
          <h6 class="section-label">
            رقم الرخصة
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('license_number').value}}
          </b-card-text>
          <h6 class="section-label">
            تاريخ الرخصة
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('license_date').value}}
          </b-card-text>
          <h6 class="section-label">
            صادر من
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('issued_by').value}}
          </b-card-text>
          <h6 class="section-label">
            تاريخ التكليف
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('commissioning_date').value}}
          </b-card-text>
          <h6 class="section-label">
            محضر التجزئة
          </h6>
          <b-card-text class="font-small-2">
            {{evaluation_feature('retail_number').value}}
          </b-card-text>

          <h6 class="section-label">
            المقيّم
          </h6>
          <b-badge variant="light-warning" class="mr-1">
            {{ evaluation.evaluated_by.name }}
          </b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
           <!-- Title -->
           <div>
           <b-card-title>تفاصيل الملكية</b-card-title>
          </div>
        <b-table-simple hover bordered responsive striped>
          <b-thead head-variant="light">
            <b-tr class="text-center">
              <b-th colspan="1">رقم هوية المالك</b-th>
              <b-th colspan="1">اسم المالك</b-th>
              <b-th colspan="1">رقم هاتف المالك</b-th>
              <b-th colspan="1">نسبة التملك</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="owner in evaluation_feature('ownership_info').value " :key="owner.owner_id" class="text-center">
              <b-td  class="text-bold text-left">{{ owner.owner_cin }}</b-td>
              <b-td  class="text-bold text-left">{{ owner.owner_name }}</b-td>
              <b-td  class="text-bold text-left">{{ owner.owner_phone }}</b-td>
              <b-td  class="text-bold text-left">{{ owner.ownership_percentage }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
  BTable,
  BThead,
  BTr,
  BTh,
  BTableSimple,
  BTbody,
  BTd,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'PropertyInfo',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BTable,
    BThead,
    BTr,
    BTh,
    BTableSimple,
    BTbody,
    BTd,
  },
  directives: {
    Ripple,
  },
  props: {
    evaluation: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    evaluation_feature(name) {
      return this.evaluation.request.features.find(
        (feature) => feature.feature.name === name,
      );
    },
  },
};
</script>

<style>
</style>

<template>
  <b-card class="mb-1">
    <b-card-title
      class="mt-1 mb-75"
    >
      <feather-icon
        icon="InfoIcon"
        size="18"
      /> {{ group.display_name }}
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row v-if="group.display_name === 'جدول التسويات'">
      <b-col>
        <b-table-simple
          hover
          striped
          bordered
          responsive
        >
          <b-thead head-variant="light">
            <b-tr class="text-center">
              <b-th colspan="1">
&nbsp;
              </b-th>
              <b-th colspan="2">
                المقارن الأول
              </b-th>
              <b-th colspan="2">
                المقارن الثاني
              </b-th>
              <b-th colspan="2">
                المقارن الثالث
              </b-th>
            </b-tr>
            <b-tr
              v-for="(field, c) in evaluation.comparatives[0].settlments"
              :id="field.id"
              :key="`settlment-1-date-${c}`"
              class="text-center"
            >
              <b-td
                v-if="settlements[0].settlemts1[c].feature.value_type == 'date'"
                class="text-bold text-left"
              >
                {{ settlements[0].settlemts1[c].feature.display_name }}
              </b-td>
              <template v-for="(comparative, k) in evaluation.comparatives">
                <b-td
                  v-if="settlements[0].settlemts1[c].feature.value_type == 'date'"
                  :key="`date-${k}-${c}`"
                  colspan="2"
                >
                  {{ evaluation.comparatives[k].settlments[c].value }}
                </b-td>
              </template>
            </b-tr>
            <b-tr class="text-center">
              <b-th />
              <b-th>القيمة</b-th>
              <b-th>نسبةالتسوية</b-th>
              <b-th>القيمة</b-th>
              <b-th>نسبةالتسوية</b-th>
              <b-th>القيمة</b-th>
              <b-th>نسبةالتسوية</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(field, c) in settlements[0].settlemts1"
              :id="field.id"
              :key="`settlment1-1-${c}`"
              class="text-center"
            >
              <b-th
                v-if="['string', 'object'].includes(field.feature.value_type)"
                class="text-bold text-left"
              >
                {{ field.feature.display_name }}
              </b-th>
              <template v-for="k in [0, 1, 2]">
                <b-td
                  v-if="field.feature.value_type == 'string'"
                  :key="`settlemts1-input-${k}-${c}`"
                  colspan="2"
                >
                  {{ evaluation.comparatives[k].settlments[c].value }}
                </b-td>
                <template v-if="field.feature.value_type == 'object'">
                  <b-td
                    v-for="(subField, s) in field.feature.value_options.fields"
                    :key="`subfield-${s}-${k}`"
                  >
                    {{ evaluation.comparatives[k].settlments[c].value[subField.name] }}
                  </b-td>
                </template>
              </template>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                اجمالي نسب التسويات
              </b-th>
              <b-td colspan="2">
                {{ calculatePrimaryTotalAdjustment(0) }}%
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryTotalAdjustment(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryTotalAdjustment(2) }}
              </b-td>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                قيمة نسب التسويات
              </b-th>
              <b-td colspan="2">
                {{ calculatePrimaryAdjustmentValue(0) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryAdjustmentValue(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryAdjustmentValue(2) }}
              </b-td>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                السعر بعد التعديلات
              </b-th>
              <b-td colspan="2">
                {{ calculatePrimaryPriceAfterAdjustments(0) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryPriceAfterAdjustments(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePrimaryPriceAfterAdjustments(2) }}
              </b-td>
            </b-tr>
            <b-tr
              v-for="(field, c) in evaluation.comparatives[0].settlments.slice(
                settlements[0].settlemts1.length
              )"
              :id="
                settlements[0].settlemts2[getSettlmentIndex(field.property_feature_id)].id
              "
              :key="`settlment2-1-${c}`"
              style="position: relative"
              class="text-center"
            >
              <b-th
                v-if="c > 0"
                class="text-bold text-left"
              >
                {{
                  evaluation.comparatives[0].settlments[
                    c + settlements[0].settlemts1.length
                  ].label
                }}
              </b-th>
              <b-th
                v-if="c == 0"
                class="text-bold text-left"
              >
                {{
                  settlements[0].settlemts2[getSettlmentIndex(field.property_feature_id)]
                    .feature.display_name
                }}
              </b-th>
              <template v-for="k in [0, 1, 2]">
                <b-td
                  v-if="
                    settlements[0].settlemts2[
                      getSettlmentIndex(field.property_feature_id)
                    ].value_type == 'string'
                  "
                  :key="`settlemts2-input-${k}-${c}`"
                  colspan="2"
                >
                  {{
                    evaluation.comparatives[k].settlments[
                      c + settlements[0].settlemts1.length
                    ].value
                  }}
                </b-td>
                <template
                  v-if="
                    settlements[0].settlemts2[
                      getSettlmentIndex(field.property_feature_id)
                    ].feature.value_type == 'object'
                  "
                >
                  <b-td
                    v-for="(subField, s) in settlements[0].settlemts2[
                      getSettlmentIndex(field.property_feature_id)
                    ].feature.value_options.fields"
                    :key="`subfield-${s}-${k}`"
                  >
                    {{
                      evaluation.comparatives[k].settlments[
                        c + settlements[0].settlemts1.length
                      ].value[subField.name]
                    }}
                  </b-td>
                </template>
              </template>
              <b-button
                v-if="c > 0"
                class="btn-icon"
                variant="danger"
                size="sm"
                style="
                  position: absolute;
                  top: 20px;
                  left: 6px;
                  width: 18px;
                  height: 18px;
                  padding: 0;
                "
                @click="removeSettlment(c + settlements[0].settlemts1.length)"
              >
                <feather-icon
                  icon="XIcon"
                  size="12"
                />
              </b-button>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                اجمالي نسب التسويات
              </b-th>
              <b-td colspan="2">
                {{ calculateTotalAdjustment(0) }}%
              </b-td>
              <b-td colspan="2">
                {{ calculateTotalAdjustment(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculateTotalAdjustment(2) }}
              </b-td>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                قيمة نسب التسويات
              </b-th>
              <b-td colspan="2">
                {{ calculateAdjustmentValue(0) }}
              </b-td>
              <b-td colspan="2">
                {{ calculateAdjustmentValue(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculateAdjustmentValue(2) }}
              </b-td>
            </b-tr>
            <b-tr class="text-center">
              <b-th class="text-bold text-left">
                السعر بعد التعديلات
              </b-th>
              <b-td colspan="2">
                {{ calculatePriceAfterAdjustments(0) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePriceAfterAdjustments(1) }}
              </b-td>
              <b-td colspan="2">
                {{ calculatePriceAfterAdjustments(2) }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        v-for="(feature, i) in group.features"
        :id="feature.id"
        :key="i"
        cols="12"
        md="6"
      >
        <div class="mb-1">
          <h6 class="section-label">
            {{ feature.feature.display_name }}
          </h6>
          <b-card-text class="font-small-2">
            {{ formatContent(feature.value, feature.feature) }}
          </b-card-text>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
  BTable,
  BThead,
  BTr,
  BTh,
  BTableSimple,
  BTbody,
  BTd,
} from 'bootstrap-vue';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';
import { propertyEvaluationMixin } from '@/@core/mixins/modules/evaluation/propertyEvaluation';
import Ripple from 'vue-ripple-directive';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  name: 'PropertyData',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BTable,
    BThead,
    BTr,
    BTh,
    BTableSimple,
    BTbody,
    BTd,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin, propertyEvaluationMixin],
  props: {
    group: {
      type: Object,
    },
    evaluation: {
      type: Object,
    },
  },

  data() {
    return {
      config: {
        locale: Arabic,
      },
      fields: ['المقارن الاول', 'المقارن الثاني', 'المقارن الثالث'],
      settlement: {},
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      comparativesLabels: ['المقارن الأول', 'المقارن الثاني', 'المقارن الثالث'],
      yesNoOptions: [
        { text: 'نعم', value: true },
        { text: 'لا', value: false },
      ],
    };
  },
  methods: {
    formatContent(content, feature) {
      if (content === null) {
        return 'غير محدد';
      } if (Array.isArray(content)) {
        return content.join(', ');
      }
      // we display based on the feature.value_type
      switch (feature.value_type) {
        case 'boolean':
          return content ? 'نعم' : 'لا';
        case 'date':
          return this.$moment(content).format('YYYY-MM-DD');
        case 'datetime':
          return this.$moment(content).format('YYYY-MM-DD HH:mm:ss');
        case 'time':
          return this.$moment(content).format('HH:mm:ss');
        case 'integer':
          return this.$numeral(content).format('0,0');
        case 'decimal':
          return this.$numeral(content).format('0,0.00');
        case 'string':
        default:
          return content;
      }
    },
  },
};
</script>

<style>
</style>

<template>
  <b-card class="mb-1">
    <b-card-title
      class="mt-1 mb-75"
    >
      <feather-icon
        icon="InfoIcon"
        size="18"
      />  مواصفات العقار
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row>
      <b-col
        v-for="(feature, i) in propertyInfo"
        :id="feature.id"
        :key="i"
        cols="12"
        md="6"
      >
        <div class="mb-1">
          <h6 class="section-label">
            {{ feature.feature.display_name }}
          </h6>
          <b-card-text class="font-small-2">
            {{ formatContent(feature.value) }}
          </b-card-text>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'PropertySpecification',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    propertyInfo: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    formatContent(content) {
      if (content === null) {
        return 'غير محدد';
      } if (Array.isArray(content)) {
        return content.join(', ');
      }
      return content;
    },
  },
};
</script>

<style>
</style>

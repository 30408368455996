<template>
  <b-card class="mb-1">
    <b-card-title
      class="mt-1 mb-75"
    >
      <feather-icon
        icon="InfoIcon"
        size="18"
      /> معلومات العقار
    </b-card-title>
    <hr class="mb-2 mt-1">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="column-1">
          <h6 class="section-label">
            العميل
          </h6>
          <b-card-text class="font-small-2">
            {{ evaluation.request.client.name }}
          </b-card-text>
            <h6 class="section-label">
              عرض السعر
            </h6>
            <b-card-text class="font-small-2">
              {{ evaluation.request.quotation.reference }}
            </b-card-text>
            <h6 class="section-label">
              عقد العميل
            </h6>
            <b-card-text class="font-small-2">
              {{ evaluation.request.contract.reference }}
            </b-card-text>
          <h6 class="section-label">
            المدينة
          </h6>
          <b-card-text class="font-small-2">
            {{ property.city.name_ar }}
          </b-card-text>
          <h6 class="section-label">
            الحي
          </h6>
          <b-card-text class="font-small-2">
            {{ property.district.name_ar }}
          </b-card-text>
          <h6 class="section-label">
            اسم الشارع
          </h6>
          <b-card-text class="font-small-2">
            {{ property.street }}
          </b-card-text>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <div class="column-2">
          <h6 class="section-label">
            رقم البلوك
          </h6>
          <b-card-text class="font-small-2">
            {{ property.part_number }}
          </b-card-text>
          <h6 class="section-label">
            رقم المخطط
          </h6>
          <b-card-text class="font-small-2">
            {{ property.sketch_number }}
          </b-card-text>
          <h6 class="section-label">
            رقم القطعة
          </h6>
          <b-card-text class="font-small-2">
            {{ property.piece_number }}
          </b-card-text>
          <h6 class="section-label">
            تصنيف العقار
          </h6>
          <b-card-text class="font-small-2">
            {{ property.classification.name }}
          </b-card-text>
          <h6 class="section-label">
            نوع العقار
          </h6>
          <b-badge
            variant="light-warning"
            class="mr-1"
          >
            {{ property.type.name }}
          </b-badge>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'Property',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    evaluation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>

<template>
  <b-card
    v-if="attachments"
    class="mb-1"
  >
    <b-card-title class="mt-1 mb-75">
      <feather-icon
        icon="PaperClip"
        size="19"
      /> المرفقات
    </b-card-title>
    <hr class="mb-2 mt-1">

    <!-- Card for صور العقار -->
    <b-row v-if="attachments.some(attachment => attachment.type === 'صور العقار')">
      <b-card
        no-body
        class="mb-1"
      >
        <div class="card-header">
          <!-- Title -->
          <div>
            <b-card-title>صور العقار</b-card-title>
          </div>
        </div>
        <b-container
          fluid
          class="p-1 "
        >
          <b-row>
            <!-- Loop through attachments of type 'صور العقار' and display images -->
            <b-col
              v-for="(attachment, i) in attachments.filter(attachment => attachment.type === 'صور العقار')"
              :key="`attachment-${i}`"
              cols="3"
            >
              <a
                :href="attachment.attachment_url"
                target="_blank"
              >
                <b-img
                  thumbnail
                  fluid
                  :src="attachment.attachment_url"
                  alt="Image"
                />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="!hasAttachmentsOfType('صور العقار')"
          class="mb-2"
        >
          لا يوجد صور العقار
        </div>
      </b-card>
    </b-row>
    <hr class="mb-2 mt-1">

    <!-- Card for صورة للصك -->
    <b-row v-if="attachments.some(attachment => attachment.type === 'صورة للصك')">
      <b-card
        no-body
        class="mb-1"
      >
        <div class="card-header">
          <!-- Title -->
          <div>
            <b-card-title>صورة للصك</b-card-title>
          </div>
        </div>
        <b-container
          fluid
          class="p-1 "
        >
          <b-row>
            <!-- Loop through attachments of type 'صور العقار' and display images -->
            <b-col
              v-for="(attachment, i) in attachments.filter(attachment => attachment.type === 'صورة للصك')"
              :key="`attachment-${i}`"
              cols="3"
            >
              <a
                :href="attachment.attachment_url"
                target="_blank"
              >
                <b-img
                  thumbnail
                  fluid
                  :src="attachment.attachment_url"
                  alt="Image"
                />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="!hasAttachmentsOfType('صورة للصك')"
          class="mb-2"
        >
          لا يوجد صورة للصك
        </div>
      </b-card>
    </b-row>
    <hr class="mb-2 mt-1">

    <!-- Card for صورة لطلب التثمين -->
    <b-row v-if="attachments.some(attachment => attachment.type === 'صورة لطلب التثمين')">
      <b-card
        no-body
        class="mb-1"
      >
        <div class="card-header">
          <!-- Title -->
          <div>
            <b-card-title>صورة لطلب التثمين</b-card-title>
          </div>
        </div>
        <b-container
          fluid
          class="p-1 "
        >
          <b-row>
            <!-- Loop through attachments of type 'صور العقار' and display images -->
            <b-col
              v-for="(attachment, i) in attachments.filter(attachment => attachment.type === 'صورة لطلب التثمين')"
              :key="`attachment-${i}`"
              cols="3"
            >
              <a
                :href="attachment.attachment_url"
                target="_blank"
              >
                <b-img
                  thumbnail
                  fluid
                  :src="attachment.attachment_url"
                  alt="Image"
                />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="!hasAttachmentsOfType('صورة لطلب التثمين')"
          class="mb-2"
        >
          لا يوجد صورة لطلب التثمين
        </div>
      </b-card>
    </b-row>
    <hr class="mb-2 mt-1">
    <!-- Card for صورة لطلب التثمين -->
    <b-row v-if="attachments.some(attachment => attachment.type === 'أخرى')">
      <b-card
        no-body
        class="mb-1"
      >
        <div class="card-header">
          <!-- Title -->
          <div>
            <b-card-title>أخرى </b-card-title>
          </div>
        </div>
        <b-container
          fluid
          class="p-1 "
        >
          <b-row>
            <!-- Loop through attachments of type 'صور العقار' and display images -->
            <b-col
              v-for="(attachment, i) in attachments.filter(attachment => attachment.type === 'أخرى')"
              :key="`attachment-${i}`"
              cols="3"
            >
              <h5>{{ attachment.label }} </h5>
              <a
                :href="attachment.attachment_url"
                target="_blank"
              >
                <b-img
                  thumbnail
                  fluid
                  :src="attachment.attachment_url"
                  alt="Image"
                />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <div
          v-if="!hasAttachmentsOfType('أخرى')"
          class="mb-2"
        >
          لا يوجد صورة لطلب التثمين
        </div>
      </b-card>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BButton,
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'Attachments',
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    attachments: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    hasAttachmentsOfType(type) {
      return this.attachments.some((attachment) => attachment.type === type);
    },
    getAttachmentUrlByType(type) {
      const attachment = this.attachments.find((attachment) => attachment.type === type);
      return attachment ? attachment.attachment_url : '';
    },
  },
};
</script>

<style> .bg-custom-color {
   background-color: #F2A831;
   /* Replace this with your desired color code */
   /* Additional styling if needed */
 }
</style>
